import { SupplementaryInvoiceConstant } from "presentation/constant/SupplementaryInvoice/SupplementaryInvoiceConstant";
import { useSupplementaryInvoiceVM } from "presentation/hook/SupplementaryInvoice/useSupplementaryInvoiceVM";
import { useSupplementaryInvoiceTracked } from "presentation/store/SupplementaryInvoice/SupplementaryInvoiceProvider";
import { useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import SupplementaryInvoiceSearchForm from "./SupplementaryInvoiceSearchForm";

const SupplementaryInvoiceSearchPanel = () => {
    const SUPPLEMENTARY_INVOICE_CONSTANT = SupplementaryInvoiceConstant.Search;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [supplementaryInvoiceState] = useSupplementaryInvoiceTracked();
    const {searchCriteria} = supplementaryInvoiceState;
    const supplementaryInvoiceVM = useSupplementaryInvoiceVM();

    const resetButtonClicked = useCallback(() => {
        supplementaryInvoiceVM.onSearchCriteriaResetClick();
    }, [supplementaryInvoiceVM]);
    
    const searchButtonClicked = useCallback(() => {
        setIsLoading(true);
        supplementaryInvoiceVM.onSearch(searchCriteria).then((data) => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
        
    }, [searchCriteria, supplementaryInvoiceVM])
    
    return (
        <div className='side-form-content-left-wrapper' style={{padding:"20px 20px 20px 0px"}}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{SUPPLEMENTARY_INVOICE_CONSTANT.SEARCH_CRITERIA}</SidebarTitle>
                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={supplementaryInvoiceVM.onSearchClick} />
                        </SidebarActionCross>
                    </Sidebarheader>                
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" /> }

            <div className={'add-edit-form'} style={{ maxHeight:'75vh' , height:'75vh', overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        
                        <SupplementaryInvoiceSearchForm/>
                        
                    </div>                  
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={searchButtonClicked} />
            </SidebarActionBar>
        </div>
    );
}
export default SupplementaryInvoiceSearchPanel;